<template>
  <div>
    <custom-header :title="'Барааны үнэ батлуулах хуудас'" />
    <el-form label-position="top" :model="transferForm" ref="transferForm" v-loading="loading">
      <el-row :gutter="20">
        <el-col :span="16">
          <div class="panel">
            <div class="panel-item">
              <el-form-item
                label="БРЭНД"
                prop="brand_id"
                :rules="[{ required: true, message: 'Брэндээ сонгоно уу', trigger: 'blur' }]">
                <el-row :gutter="10" type="flex" align="bottom">
                  <el-col :span="16">
                    <el-select
                      style="width: 100%"
                      v-model="transferForm.brand_id"
                      @change="setCurrentBrand"
                      filterable
                      placeholder="Брэнд"
                    >
                      <el-option
                        v-for="(item, index) in brands"
                        :key="index"
                        :label="item.brand_monName"
                        :value="item.brand_id"
                      >
                      </el-option>
                    </el-select>
                  </el-col>
                  <el-col :span="8">
                    <el-button v-if="currentBrand" type="default" class="block" @click="setCurrentBrand(currentBrand.brand_id)">Брэнд сонгох</el-button>
                  </el-col>
                </el-row>
              </el-form-item>
            </div>
          </div>
        </el-col>
      </el-row>
    </el-form>
    <el-table
      :data="transferForm.products"
      style="width: 100%"
      default-expand-all>
      <el-table-column type="expand">
        <template slot-scope="props">
          <el-table size="small" :data="props.row.variants.filter(data => data.checked === true ? data : '')">
            <el-table-column label="Зураг">
              <template slot-scope="props"><img :src="`${props.row.variant_image}`" alt="" style="width:50px"></template>
            </el-table-column>
            <el-table-column prop="variant_name" label="Төрөл"></el-table-column>
            <el-table-column prop="variant_getPrice" label="Татах үнэ"></el-table-column>
            <el-table-column prop="variant_sellPrice" label="Зарах үнэ"></el-table-column>
            <el-table-column prop="variant_sale_price" label="Хямдралтай зарах үнэ"></el-table-column>
            <el-table-column prop="variant_sku" label="SKU"></el-table-column>
            <el-table-column
              prop="getPrice"
              label="Авах үнэ">
                <template slot-scope="scope">
                  <el-input-number v-model="scope.row.getPrice" size="small" controls-position="right"/>
                </template>
            </el-table-column>
            <el-table-column
               prop="sellPrice"
              label="Зарах үнэ">
              <template slot-scope="scope">
                <el-input-number v-model="scope.row.sellPrice" size="small" controls-position="right" />
              </template>
            </el-table-column>
            <el-table-column label="Хямдралтай татах үнэ">
              <template slot-scope="scope">
                <el-input placeholder="Татах үнэ" v-model="scope.row.get_discounted_price"  :disabled="scope.row.discountCheck === false ? true : scope.row.discountCheck === undefined ? true : false"></el-input>
              </template>
            </el-table-column>
            <el-table-column
              label="Хямдралтай үнэ">
              <template slot-scope="scope">
                <el-input placeholder="Хямдралтай үнэ" v-model="scope.row.discountedPrice"
                :disabled="scope.row.discountCheck === false ? true : scope.row.discountCheck === undefined ? true : false"></el-input>
              </template>
            </el-table-column>
            <el-table-column label="Эхлэх өдөр">
              <template slot-scope="scope">
                <div class="block">
                  <el-date-picker value-format="yyyy-MM-dd HH:mm:ss" type="datetime" placeholder="Эхлэх огноо" v-model="scope.row.start_date" :disabled="scope.row.discountCheck === false ? true : scope.row.discountCheck === undefined ? true : false"></el-date-picker>
                </div>
              </template>
            </el-table-column>
            <el-table-column label="Дуусах өдөр">
              <template slot-scope="scope">
                <el-date-picker value-format="yyyy-MM-dd HH:mm:ss" type="datetime" placeholder="Дуусах огноо" v-model="scope.row.end_date" :disabled="scope.row.discountCheck === false ? true : scope.row.discountCheck === undefined ? true : false"></el-date-picker>
              </template>
            </el-table-column>
            <el-table-column align="center"
              label="Хямдралтай эсэх">
              <template slot-scope="scope">
                <el-checkbox v-model="scope.row.discountCheck"></el-checkbox>
              </template>
            </el-table-column>
            <el-table-column
              label="Бохир ашгийн маржин">
              <template slot-scope="scope">
                <strong><span v-if="scope.row.sellPrice !== undefined && scope.row.getPrice !== undefined && scope.row.sellPrice !== '' && scope.row.getPrice !== '' || scope.row.discountedPrice !== undefined && scope.row.get_discounted_price !== undefined && scope.row.get_discounted_price !== '' && scope.row.discountedPrice !== ''">{{(((((scope.row.discountedPrice ? scope.row.discountedPrice / 1.1 : scope.row.sellPrice / 1.1).toFixed(2) - (scope.row.get_discounted_price ? scope.row.get_discounted_price / 1.1 : scope.row.getPrice / 1.1).toFixed(2)).toFixed(2)) * 100) / (scope.row.discountedPrice ? scope.row.discountedPrice / 1.1 : scope.row.sellPrice / 1.1).toFixed(2)).toFixed(2) + '%'}}</span></strong>
              </template>
            </el-table-column>
            <!-- <el-table-column>
            <template slot-scope="scope">
              <el-button
                @click.native.prevent="changePrice(scope.row)"
                type="success"
                size="small" round>
                Нэмэх
              </el-button>
            </template>
          </el-table-column> -->
          </el-table>
        </template>
      </el-table-column>
      <el-table-column
        label="Зураг">
        <template slot-scope="scope">
          <img :src="`${scope.row.image}`" alt="" style="width: 50px">
        </template>
      </el-table-column>
      <el-table-column
        prop="brand_name"
        label="Брэнд">
      </el-table-column>
      <el-table-column
        prop="name_mon"
        label="Бүтээгдэхүүн">
      </el-table-column>
      <el-table-column align="center">
        <template slot-scope="scope">
          <el-button
            @click.native.prevent="deleteRow(scope.$index, transferForm.products)"
            type="danger"
            size="small"
            icon="el-icon-delete">
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <div class="text-right mt20">
      <el-button type="default" @click="$router.go(-1)">Буцах</el-button>
      <el-button type="success" @click="changePrice">Хадгалах</el-button>
    </div>
    <el-dialog
      title="Брэнд сонгох"
      :visible.sync="visibleProductDialog"
      top="10vh"
      width="500"
    >
      <el-row :gutter="20">
        <el-col :span="24">
          <el-input
            placeholder="Брэндийн нэр"
            v-model="searchText"
            @change="onSearch"
          >
            <template slot="prepend"><i class="el-icon-search"></i></template>
          </el-input>
        </el-col>
      </el-row>
      <el-row class="mt20">
        <ul class="transfer-product-list" v-if="products && products.length > 0">
          <li v-for="(product, index) in products" :key="index">
            <el-row :gutter="10" type="flex" align="middle">
              <el-col :span="2">
                <div class="image-holder">
                  <img :src="Array.isArray(product.image) ? product.image[0] : product.image" alt="">
                </div>
              </el-col>
              <el-col :span="20">
                {{product.name_mon}}
              </el-col>
              <el-col :span="2" class="text-right">
                <el-checkbox v-model="product.checked" :indeterminate="product.isIndeterminate" @change="checkProduct(product)"></el-checkbox>
              </el-col>
            </el-row>
            <ul>
              <li v-for="(variant, index1) in product.variants" :key="index1">
                <el-row :gutter="20" type="flex" align="middle">
                  <el-col :span="18" :offset="2">
                    <div>
                      {{variant.variant_name}}
                    </div>
                    <div class="text-color-secondary">
                      {{variant.variant_sku}}
                    </div>
                  </el-col>
                  <el-col :span="2">
                    <div class="text-center">
                      {{variant.availableCount}}
                    </div>
                  </el-col>
                  <el-col :span="2" class="text-right">
                    <el-checkbox v-model="variant.checked" @change="checkVariant(product, variant)"></el-checkbox>
                  </el-col>
                </el-row>
              </li>
            </ul>
          </li>
        </ul>
      </el-row>
      <span slot="footer" class="dialog-footer">
        <el-pagination
          @current-change = "curentPageHandler"
          @size-change = "sizeChangeHandler"
          class="text-right mt10 mb20"
          background
          :current-page="currentPage"
          :page-size="pageSize"
          :page-sizes="[5, 20, 50, 100]"
          layout="total, sizes, prev, pager, next"
          :total="totalCount"
        >
        </el-pagination>
        <el-button @click="visibleProductDialog = false">Буцах</el-button>
        <el-button @click="checkedOrders(products)" type="primary">Сонгох</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import services from '../../../helpers/services'
import { getName } from '../../../utils/auth'
import customHeader from '../../../components/common/customHeader'
export default {
  name: 'addPrice',
  components: {
    customHeader
  },
  data () {
    return {
      totalCount: 0,
      pageSize: 20,
      currentPage: 1,
      start_date: '',
      end_date: '',
      transferForm: {
        brand_id: '',
        products: [],
        warehouse_name: 'default',
        warehouse_id: null,
        expected_arrival_at: null
      },
      variants: [],
      brands: [],
      loading: false,
      searchText: '',
      products: [],
      visibleProductDialog: false,
      currentBrand: null
    }
  },
  async created () {
    this.getBrands()
    this.userName = await getName()
  },
  methods: {
    onSearch () {
      this.getProductsWithVariant((this.pageSize * (this.currentPage - 1)), this.pageSize, this.status, this.searchText)
    },
    sizeChangeHandler (item) {
      this.pageSize = item
      this.getProductsWithVariant((this.pageSize * (this.currentPage - 1)), this.pageSize, this.status, this.searchText)
    },

    curentPageHandler (item) {
      this.currentPage = item
      this.getProductsWithVariant((this.pageSize * (this.currentPage - 1)), this.pageSize, this.status, this.searchText)
    },

    deleteRow (index, rows) {
      rows.splice(index, 1)
    },

    checkedOrders (product) {
      const tempProducts = []
      product.forEach(data => {
        const variants = [...data.variants.filter(el => el.checked)]
        if (variants.length > 0) {
          data.variants = variants
          data.getPrice = parseInt(data.get_price)
          data.variants.forEach(el => {
            el.getPrice = parseInt(el.variant_getPrice)
            el.sellPrice = parseInt(el.variant_sellPrice)
          })
          tempProducts.push(data)
        }
      })
      if (tempProducts.length > 0) {
        this.transferForm.products = tempProducts
        this.visibleProductDialog = false
      }
    },
    checkProduct (product) {
      product.variants.forEach(variant => {
        variant.checked = product.checked
      })
    },
    checkVariant (product, variant) {
      let checkedCount = 0
      product.variants.forEach(variant => {
        if (variant.checked) {
          checkedCount = checkedCount + 1
        }
      })
      product.checked = checkedCount === product.variants.length
      product.isIndeterminate = checkedCount > 0 && checkedCount < product.variants.length
    },
    async getProductsWithVariant () {
      const promise = new Promise((resolve) => {
        const query = '?size=' + this.pageSize + '&from=' + (this.pageSize * (this.currentPage - 1)) + '&search_text=' + this.searchText
        services.getProductByBrand(this.currentBrand.brand_id, query).then(response => {
          if (response.status === 'success') {
            this.totalCount = response.total
            response.data.forEach(product => {
              const property = {
                checked: false,
                isIndeterminate: false
              }
              Object.assign(product, property, product)
              product.variants.forEach(variant => {
                const property = {
                  checked: false,
                  number_tobe_transferred: 0
                }
                Object.assign(variant, property, variant)
              })
              product.variants.forEach(el => {
                this.variants.push(el)
              })
            })
          }
          this.products = response.data
          resolve()
        })
      })
      await promise
    },
    getBrands () {
      services.getBrands('?size=10000').then(response => {
        this.brands = response.datas
      })
    },
    async setCurrentBrand (val) {
      this.brands.forEach((element) => {
        if (element.brand_id === val) {
          this.currentBrand = element
        }
      })
      this.transferForm.brand_id = this.currentBrand.brand_id
      await this.getProductsWithVariant()
      if (this.products && this.products.length > 0) {
        this.visibleProductDialog = true
      } else {
        this.$notify({
          title: 'Алдаа гарлаа',
          message: 'Тухайн брэнд дээр бүтээгдэхүүн байхгүй байна.',
          type: 'error',
          position: 'bottom-right'
        })
      }
    },
    changePrice () {
      const payload = {
        accepted_prices: []
      }
      this.transferForm.products.forEach(element => {
        element.variants.forEach(el => {
          const tempBody = {}
          if (element.productId === el.product_id) {
            tempBody.supplier_id = element.supplier_id
            tempBody.product_id = element.productId
            tempBody.brand_id = element.brand_id
            tempBody.variant_id = el.variant_id
            tempBody.get_price = el.getPrice ? parseInt(el.getPrice) : 0
            tempBody.sell_price = el.sellPrice ? parseInt(el.sellPrice) : 0
            tempBody.discounted_price = el.discountedPrice ? parseInt(el.discountedPrice) : 0
            tempBody.is_discount = el.discountCheck ? el.discountCheck : false
            tempBody.product_name = element.name_mon
            tempBody.brand_id = element.brand_id
            tempBody.brand_fee = parseFloat(el.brand_fee)
            tempBody.start_date = el.start_date
            tempBody.end_date = el.end_date
            tempBody.get_discounted_price = el.get_discounted_price ? parseInt(el.get_discounted_price) : 0
          }
          payload.accepted_prices.push(tempBody)
        })
      })
      services.postAcceptPrice(payload).then((response) => {
        if (response.status === 'success') {
          this.$router.push({
            name: 'supplierList'
          })
          this.$notify({
            title: 'Амжилттай',
            message: response.message,
            type: 'success'
          })
        } else if (response.status === 'error') {
          this.$notify({
            title: 'Алдаа',
            message: response.message,
            type: 'error'
          })
        }
      })
    }
  }
}
</script>
<style>
.el-input-number.is-controls-right .el-input-number__decrease {
  display: none;
}
.el-input-number.is-controls-right .el-input-number__increase {
  display: none;
}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none !important;
  margin: 0 !important;
}

/* Firefox */
input[type=number] {
  -moz-appearance: none !important;
}
</style>
